import React, {useState, useEffect, useCallback}  from 'react';
import {Button, Flex, WhiteSpace, WingBlank, Modal, ActionSheet} from 'antd-mobile-v2';
import {Link} from 'react-router-dom';
import QueueAnim from "rc-queue-anim";
import {configData} from "../../api/auth";
import config from '../../config/index'

const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
let wrapProps;
if (isIPhone) {
  wrapProps = {
    onTouchStart: e => e.preventDefault(),
  };
}

const MainScreen = ((props) => {

    const [showModal, setShowModal] = useState(false);

    const account = {
        logo: localStorage.getItem('sellerLogo'),
        client: localStorage.getItem('userName'),
        sellerName: localStorage.getItem('sellerName')
    };

    const [checkCode, setCheckCode] = useState(
        localStorage.getItem('checkCode') || ''
    );

    const [processingType, setProcessingType] = useState(
        localStorage.getItem('processingType') || ''
    );

    const showActionSheet = useCallback(() => {
        const BUTTONS = ['Да', 'Отмена'];
        ActionSheet.showActionSheetWithOptions({
          options: BUTTONS,
          className: "custom-action-sheet",
          cancelButtonIndex: 1,
          // title: 'title',
          message: 'Вы уверены что хотите выйти?',
          maskClosable: true,
          'data-seed': 'logId',
          wrapProps,
        },
        (buttonIndex) => {
            if (buttonIndex === 0){
                window.location.href = `${process.env.PUBLIC_URL}/auth`;
            }
        });
    },[])

    useEffect(() => {
        localStorage.setItem('checkCode', checkCode)
        localStorage.setItem('processingType', processingType)
    }, [checkCode,processingType]);

    useEffect(() => {
        configData()
            .then(({ result }) => {
                let res = result[0];
                setCheckCode(res.n_check_code);
                setProcessingType(res.n_processing_type)
            })
    },[]);

    return (
        <div className="main-block">
            <Flex direction="column" justify="around" alignContent="start">
                <WingBlank>
                    <WhiteSpace style={{paddingTop: 30}} />
                    <QueueAnim
                        delay={300}
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, -50] },
                            { opacity: [1, 0], translateY: [0, 50] }
                        ]}
                        className="queue-simple"
                    >
                        <Flex key='a' justify="center">
                            <Flex.Item style={{flex: 1}}>
                                <span className="seller-logo">
                                    <img className="block-logo-seller" src={account.logo} alt="Логотип" />
                                </span>
                            </Flex.Item>
                            <Flex.Item style={{flex: 2}} className="block-text-header">
                                <Flex className="block-text-fio">
                                    {account.client}
                                </Flex>
                                <Flex className="block-text-company">
                                    {account.sellerName}
                                </Flex>
                            </Flex.Item>
                        </Flex>
                    </QueueAnim>
                </WingBlank>
                <WhiteSpace style={{paddingTop: 120}} />
                <WingBlank>
                    <QueueAnim
                        delay={300}
                        className="queue-simple"
                        animConfig={[
                            { opacity: [1, 0], translateY: [0, 50] },
                            { opacity: [1, 0], translateY: [0, -150] }
                        ]}
                    >
                    <Flex key='a' className="action-group-block main-block-header button-action" justify="center" >
                        <Button className="main-button-card" onClick={() =>  window.open(`${config.api.userCreate}${localStorage.getItem('token')}`, '_blank')} >
                            <Flex justify="center" direction="column">
                                <Flex.Item>Новый клиент</Flex.Item>
                                <Flex.Item><div className="find-client-img" /> <img src="/img/newClient.png" alt="Узнать клиента" /></Flex.Item>
                            </Flex>
                        </Button>
                        <WhiteSpace size="lg" />
                        <Button className="main-button-card" onClick={e => setShowModal(true)} >
                            <Flex justify="center" direction="column">
                                <Flex.Item>Узнать клиента</Flex.Item>
                                <Flex.Item><div className="find-client-img" /> <img src="/img/userProfile.png" alt="Узнать клиента" /></Flex.Item>
                            </Flex>
                        </Button>
                        <WhiteSpace style={{paddingTop: 60}} />
                        <Button className="main-button-card logout-button" onClick={e => showActionSheet()} >
                            <Flex justify="center" direction="column">
                                <Flex.Item>Выход</Flex.Item>
                            </Flex>
                        </Button>

                        <Modal
                            animationType="slide-up"
                            popup
                            visible={showModal}
                            transparent
                            onClose={e => setShowModal(false)}
                        >
                            <Flex wrap align="start" justify="center" alignContent="end">
                                <Flex.Item style={{textAlign: 'center', borderRight: "1px solid #E0E0E0"}}>
                                    <Link to={`${process.env.PUBLIC_URL}/findPhone`}>
                                        <>
                                            <div className="subtitle-block">По номеру телефона</div>
                                            <WhiteSpace size="lg" />
                                            <WingBlank size="md"><img src="/img/phone.png" alt="По номеру телефона" /></WingBlank>
                                        </>
                                    </Link>
                                </Flex.Item>
                                <Flex.Item style={{textAlign: 'center'}}>
                                    <Link to={`${process.env.PUBLIC_URL}/findQR`}>
                                        <>
                                            <div className="subtitle-block">Сканировать QR код</div>
                                            <WhiteSpace size="lg" />
                                            <WingBlank size="md"><img src="/img/Qr.png" alt="По QR" /></WingBlank>
                                        </>
                                    </Link>
                                </Flex.Item>
                            </Flex>
                        </Modal>
                    </Flex>
                    </QueueAnim>
                </WingBlank>
            </Flex>
        </div>
    )
});

export default MainScreen